import { GthConversationModel, GthMessageModel, GthUserModel } from '@sentinels/models';

export type Reporting = {
  id?: string;
  tag: string;

  createdBy: string;
  createdOn: Date;
  createdByUser?: GthUserModel;

  action: ReportingAction;
  respondedBy?: string;
  respondedOn?: Date;
  strikes?: number;
  banned?: boolean;

  _contextShown?: boolean;
};

export type ReportingConversation = Reporting & {
  tag: 'conversation';
  conversationId: string;
  conversation?: GthConversationModel;
}

export type ReportingConversationMessage = Reporting & {
  tag: 'conversation_message';
  conversationId: string;
  messageId: string;

  conversation?: GthConversationModel;
  message?: GthMessageModel;
}

export type ContentWarning = {
  id: string;
  uid: string;
  strikes: number;
  banned: boolean;
}

export enum ReportingAction {
  Pending = 'pending',
  None = 'none',
  Warning = 'warning',
  Ban = 'ban',
};
