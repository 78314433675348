import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '@environments/environment';
import { Reporting, ReportingAction, ReportingConversation, ReportingConversationMessage } from '@index/interfaces/reporting';
import { GthConversationModel, GthMessageModel, GthUserModel } from '@sentinels/models';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';

import { FirestoreService } from '../core/firebase.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingService extends FirestoreService<Reporting> {
  protected basePath = 'admin_reporting';

  constructor(
    firestore: AngularFirestore,
  ) {
    super(firestore);
  }

  /**
   * Report a conversation for admin to review
   * @param {string} conversationId Id of the conversation that is being reported
   * @param {string} reportingUser Id of the user that is reporting the action
   * @return {string | boolean}
   */
  reportConversation(
    conversationId: string,
    reportingUser: string,
  ) {
    const report: ReportingConversation = {
      tag: 'conversation',
      createdBy: reportingUser,
      createdOn: new Date(),
      action: ReportingAction.Pending,
      conversationId,
    };
    return this.create(report);
  }

  /**
   * Report a message for admin to review
   * @param {string} conversationId Id of the conversation that is being reported
   * @param {string} messageId Id of the message being reported
   * @param {string} reportingUser Id of the user that is reporting the action
   * @return {string | boolean}
   */
  reportMessage(
    conversationId: string,
    messageId: string,
    reportingUser: string,
  ) {
    const report: ReportingConversationMessage = {
      tag: 'conversation_message',
      createdBy: reportingUser,
      createdOn: new Date(),
      action: ReportingAction.Pending,
      conversationId,
      messageId,
    };
    return this.create(report);
  }

  async getPendingModeration(): Promise<Reporting[]> {
    try {
      const uri = this.getFunctionUri(`getPendingModeration`);

      const callable = httpsCallableFromURL(getFunctions(), uri);

      const reports = (await callable()).data as unknown as any[];

      if (reports?.length) {
        return reports.map((r) => {
          const { createdByUser, data } = r;
          const { conversation, message } = data;

          if (createdByUser) {
            r.createdByUser = new GthUserModel(createdByUser.id, createdByUser);
          }
          if (conversation) {
            r.conversation = new GthConversationModel(conversation.id, conversation);
          }
          if (message) {
            r.message = new GthMessageModel(message.id, message);
          }
          return r as Reporting;
        });
      }
      return reports;
    } catch (error) {
      console.error('Error:', error);

      return [];
    }
  }

  async moderate(id: string, action: ReportingAction): Promise<boolean> {
    try {
      const uri = this.getFunctionUri(`moderateReport`);

      const callable = httpsCallableFromURL(getFunctions(), uri);

      return (await callable({ id, action })).data as unknown as boolean;
    } catch (error) {
      console.error('Error:', error);

      return false;
    }
  }

  private getFunctionUri(functionName: string) {
    const env = this.getEnviroment();

    // eslint-disable-next-line max-len
    return `https://us-central1-gametimehero-${env}.cloudfunctions.net/contentModeration-triggers-${functionName}`;
  }

  private getEnviroment(): string {
    return environment.envName!;
  }
}
